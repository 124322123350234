/* Add this CSS to your stylesheets or use a CSS-in-JS solution */
.basket-container {
    position: relative;
    z-index: 1000;
  }
  
  .basket {
    position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 10px;
  }
  
  .basket ul {
    list-style: none;
    padding: 0;
  }
  
  .basket li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .basket img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }
  